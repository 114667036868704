import { Home } from './Pages';
import './baseStyle.css';

function App() {
  return (
      <Home />
  );
}

export default App;
