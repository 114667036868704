import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import Dialog from '../components/Modal';

const backgroundImage =
  'https://images.unsplash.com/photo-1611607336518-66e5c6f37083?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=80';

export default function ProductHero() {
  const [open, setOpen] = React.useState(false);

  const handleModelOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog
      handleClose={handleClose}
      open={open}
      />
      <ProductHeroLayout
        sxBackground={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: '#7fc7d9', // Average color of the background image.
          backgroundPosition: 'center',
        }}
      >
        {/* Increase the network loading priority of the background image. */}
        <img
          style={{ display: 'none' }}
          src={backgroundImage}
          alt="increase priority"
        />
        <Typography color="inherit" align="center" variant="h2" marked="center">
          One stop solution for all your photography needs
        </Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h5"
          sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
        >
          Photo color lab rate list
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          onClick={handleModelOpen}
          sx={{ minWidth: 200 }}
        >
          Rate List
        </Button>
        <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
          Discover the experience
        </Typography>
      </ProductHeroLayout>
    </>
  );
}
