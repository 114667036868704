import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import instagram from '../static/instagram.png';
import whatsapp from '../static/whatsapp.png';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit" href="https://sunaynarkstudio.business.site/" target="_blank">
        RK color lab
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  }
];

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ my: 8, display: 'flex' }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: 120 }}
            >
              <Grid item sx={{ display: 'flex' }}>
                <Box component="a" href="https://www.instagram.com/_rk_studio/?hl=en" target="_blank" sx={iconStyle}>
                  <img
                    src={instagram}
                    alt="Instagram"
                  />
                </Box>
                <Box component="a" href="https://wa.me/7771055821" target="_blank" sx={iconStyle}>
                  <img
                    src={whatsapp}
                    alt="Whatsapp"
                  />
                </Box>
              </Grid>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={8} md={4}>
            <Typography variant="h6" marked="left" gutterBottom>
              Language
            </Typography>
            <TextField
              select
              size="medium"
              variant="standard"
              SelectProps={{
                native: true,
              }}
              sx={{ mt: 1, width: 150 }}
            >
              {LANGUAGES.map((language) => (
                <option value={language.code} key={language.code}>
                  {language.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid xs={6} sm={4} md={2} mt={5} ml={5}>
            <Typography variant="h6" marked="left" gutterBottom>
              Contact us
            </Typography>
            <Typography variant="h7">
              {"RK Studio & Color Lab"}
              {''}
              {" 2nd floor"}
              {" Shilpi Plaza B-Block"}
              {" Rewa, Madhya Pradesh "}
              {" 7771055821"}
          </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              Directions
            </Typography>
                <Typography variant="h7">
                <a href="https://www.google.com/maps/dir//Sunayna+Colour+Lab+%26+RK+Studio+colour+lab/@24.5367984,81.2245758,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39845bcb205300f7:0x188d83f46ea4c8f6!2m2!1d81.2946162!2d24.5368146" target="_blank" rel="noreferrer" style={{color: '#000000', textDecoration: "none"}}>
                  Google maps
                </a>
              </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
